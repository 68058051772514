import React, { ReactElement } from "react";
import { CircularProgress, Stack } from "@mui/material";
import {
  PushableButton,
  ShadowContainer,
  EdgeContainer,
  FrontContainer,
} from "./index.styles";

interface IProps {
  buttonFunction?: "button" | "submit" | "reset" | undefined;
  size?: "md" | "sm";
  variant?: "primary" | "secondary";
  isLoading?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (e: any) => void;
  title?: string;
}

function PrettyButton({
  buttonFunction = "button",
  size = "md",
  variant = "primary",
  onClick,
  title,
  isLoading,
  disabled,
}: IProps): ReactElement {
  return (
    <PushableButton
      onClick={onClick}
      type={buttonFunction}
      disabled={disabled || isLoading}
      size={size}
      variant={variant}
    >
      {variant === "primary" && <ShadowContainer />}
      <EdgeContainer variant={variant} />
      <FrontContainer disabled={isLoading} variant={variant}>
        <Stack direction="row">
          {isLoading && (
            <CircularProgress
              size={20}
              sx={{ color: "white", marginRight: 2 }}
            />
          )}
          <div>{title}</div>
        </Stack>
      </FrontContainer>
    </PushableButton>
  );
}

export default PrettyButton;
