import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Stack,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import PrettyButton from "@components/common/PrettyButton";
import { CompanyRole } from "@helpers/types";
import { AuthContext } from "@storage/AuthProvider";
import { toast } from "react-toastify";
import { Product } from "@api";
import useApi from "@helpers/hooks/useApi";

export default function PackageList(props: { includeHome?: boolean }) {
  const { includeHome = false } = props;
  const { user } = useContext(AuthContext);
  const api = useApi();

  const [oneProduct, setOneProduct] = useState<null | Product>();
  const [threeSixtyProduct, setThreeSixtyProduct] = useState<null | Product>();
  const [checkoutLoading, setCheckoutLoading] = useState<
    "none" | "one" | "threesixty"
  >("none");

  useEffect(() => {
    if (user?.companyRole !== CompanyRole.CLIENT) {
      setOneProduct(null);
      setThreeSixtyProduct(null);
    }
    api
      .apiV1ProductsList()
      .then((res) => {
        res.data?.results?.forEach((product) => {
          if (product.name.includes("360")) {
            setThreeSixtyProduct(product);
          } else if (product.name.includes("One")) {
            setOneProduct(product);
          }
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error(error);
      });
  }, [api, user?.companyRole]);

  const handlePurchaseButton = useCallback(
    (product: "one" | "threesixty") => {
      const id =
        product === "threesixty"
          ? threeSixtyProduct?.prices?.[0].id
          : oneProduct?.prices?.[0].id;

      if (!id) {
        toast.error("No such product found");
        return;
      }

      setCheckoutLoading(product);

      api
        .apiV1CheckoutCreate({ price: id })
        .then((res) => {
          const url = res?.data.url;
          if (!url) {
            toast.error("Can't redirect to Stripe");
            return;
          }
          window.location.href = url;
        })
        .catch((error) => {
          console.error(error);
          toast.error(error);
        })
        .finally(() => {
          setCheckoutLoading("none");
        });
    },
    [api, oneProduct?.prices, threeSixtyProduct?.prices],
  );

  return (
    <section>
      <Container maxWidth="lg">
        <Box py={4} textAlign="center">
          {includeHome === false && (
            <Box mb={4}>
              <Container maxWidth="sm">
                <Typography variant="h2" gutterBottom>
                  <Typography variant="h2" component="span">
                    Get Your Weekly
                    <br />
                    Testing{" "}
                  </Typography>
                  <Typography variant="h2" component="span" color="primary">
                    Done.
                  </Typography>
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                  The best QA is the one you <em>actually</em> run.
                </Typography>
              </Container>
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader
                  title="TestTheTest One"
                  titleTypographyProps={{
                    color: "primary",
                    fontWeight: "bold",
                  }}
                />
                <CardContent
                  sx={{
                    background: "url(/assets/images/toucan-1.png)",
                    backgroundSize: "5.25rem 7.5rem",
                    backgroundPosition: "bottom left",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Box px={5}>
                    <Typography
                      variant="h3"
                      component="h2"
                      sx={{ marginBottom: 2 }}
                    >
                      {oneProduct == null && <CircularProgress size={24} />}
                      {oneProduct != null && (
                        <>
                          ${(oneProduct.prices?.[0].unit_amount ?? 0) / 100}
                          <Typography color="textSecondary" component="span">
                            / month
                          </Typography>
                        </>
                      )}
                    </Typography>
                    <Stack spacing={1} marginBottom={7}>
                      <Typography variant="body1" textAlign="left">
                        Get started with{" "}
                        <strong>weekly regression testing</strong> on one of our
                        supported platforms:
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; Websites
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; Android or iOS apps
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; Chrome extensions
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; Mac or Windows desktop apps
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="left"
                        fontWeight="bold"
                      >
                        Also includes:
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        textAlign="left"
                      >
                        &mdash; TestTheTest writes your test suite and keeps it
                        up to date.
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; 100% test case coverage weekly.
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        textAlign="left"
                      >
                        &mdash; Easy-to-understand, accurate and comprehensive
                        T3 reports.
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        textAlign="left"
                      >
                        &mdash; Your choice of reporting day, unlimited emails
                        and 5 seats to access your dashboard.
                      </Typography>
                    </Stack>
                  </Box>
                  {oneProduct == null && <CircularProgress size={20} />}
                  {oneProduct != null && includeHome === false && (
                    <PrettyButton
                      onClick={(e) => {
                        e.preventDefault();
                        handlePurchaseButton("one");
                      }}
                      title="Subscribe now"
                      isLoading={checkoutLoading === "one"}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader
                  title="TestTheTest 360°"
                  titleTypographyProps={{
                    color: "primary",
                    fontWeight: "bold",
                  }}
                />
                <CardContent>
                  <Box px={5}>
                    <Typography
                      variant="h3"
                      component="h2"
                      sx={{ marginBottom: 2 }}
                    >
                      {threeSixtyProduct == null && (
                        <CircularProgress size={24} />
                      )}
                      {threeSixtyProduct != null && (
                        <>
                          $
                          {(threeSixtyProduct.prices?.[0].unit_amount ?? 0) /
                            100}
                          <Typography color="textSecondary" component="span">
                            / month
                          </Typography>
                        </>
                      )}
                    </Typography>
                    <Stack spacing={1} marginBottom={7}>
                      <Typography variant="body1" textAlign="left">
                        Advanced weekly regression testing on{" "}
                        <strong>
                          up to 5 supported platforms and release environments
                        </strong>
                        .
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="left"
                        fontWeight="bold"
                      >
                        Includes:
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        textAlign="left"
                      >
                        &mdash; Everything in TestTheTest One:
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="left"
                        paddingLeft={2}
                      >
                        100% test case coverage, feature discovery and
                        up-to-date test suite documentation.
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        textAlign="left"
                      >
                        &mdash; Define your own release environments:
                        development, production or anything in-between
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        textAlign="left"
                        fontWeight="bold"
                      >
                        &mdash; Mix-and-match your platforms and environments,
                        and receive a comprehensive, accurate and
                        easy-to-understand bug report weekly.
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        textAlign="left"
                      >
                        &mdash; Suitable for larger teams with unlimited
                        reporting emails and 15 seats.
                      </Typography>
                    </Stack>
                  </Box>
                  {threeSixtyProduct == null && <CircularProgress size={20} />}
                  {threeSixtyProduct != null && includeHome === false && (
                    <PrettyButton
                      onClick={(e) => {
                        e.preventDefault();
                        handlePurchaseButton("threesixty");
                      }}
                      title="Subscribe now"
                      isLoading={checkoutLoading === "threesixty"}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  background: "url(/assets/images/toucan-2.png)",
                  backgroundSize: "5.125rem 9.125rem",
                  backgroundPosition: "top right",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <CardHeader
                  title="TestTheTest Enterprise"
                  titleTypographyProps={{
                    color: "primary",
                    fontWeight: "bold",
                  }}
                />
                <CardContent>
                  <Box px={5}>
                    <Typography variant="h3" component="h2" gutterBottom>
                      Custom
                      <Typography color="textSecondary" component="span">
                        &nbsp;
                      </Typography>
                    </Typography>
                    <Stack spacing={1} marginBottom={7}>
                      <Typography variant="body1" textAlign="left">
                        The Best Available Technology and on-demand enterprise
                        level support, tailored to your teams:
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; <strong>Playwright automated tests</strong>,
                        including software development and test suite
                        maintenance.
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; <strong>Appium test suite</strong> to run
                        locally, on Amazon AWS Device Farm, BrowserStack or
                        elsewhere.
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; <strong>Cloud test runners</strong>: run your
                        automated test suite directly from the TestTheTest
                        dashboard.
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; Our{" "}
                        <strong>
                          Github actions integrate with your CI pipelines
                        </strong>
                        , to capture, analyze and visualize artifacts and test
                        results.
                      </Typography>
                      <Typography variant="body1" textAlign="left">
                        &mdash; Email or Slack support, PR reviews and expert
                        guidance to help you extend the automated suite.
                      </Typography>
                    </Stack>
                  </Box>
                  {includeHome === false && (
                    <PrettyButton
                      onClick={() => {
                        window.location.href = "mailto:hello@testthetest.com";
                      }}
                      variant="secondary"
                      title="Request a demo"
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
